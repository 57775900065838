import React from 'react';

let Footer = () => {
    return(
        <footer>
            <p className="footer_copyright">© Night Witches 2021</p>
        </footer>
    )
}

export default Footer;